<template>
  <div class="block block-transparent kiosk-block">
    <div class="block-content block-content-full">
      <BarChart :options="chartData" />
    </div>
  </div>
</template>
<script>
import BarChart from '@/components/kiosk/BarChart';

export default {
  name: 'KioskEnergyLeaderboard',
  components: {
    BarChart
  },
  data() {
    return {
      chartData: {
        title: {
          text: '',
          useHTML: true
        },
        xAxis: {
          categories: ['Floor 1', 'Floor 2', 'Floor 3', 'Floor 4'],
          title: {
            text: ''
          },
          labels: {
            padding: 20
          }
        },
        yAxis: {
          title: {
            text: 'CO2 Usage',
            margin: 15
          },
          min: 0,
          labels: {
            padding: 20
          }
        },
        series: [
          {
            name: 'Year 1800',
            data: [107, 31, 635, 203]
          }
        ],
        tooltip: { enabled: false }
      }
    };
  }
};
</script>
