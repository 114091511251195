<template>
  <div class="kiosk-chart">
    <div v-if="loading || options.series.length === 0" class="d-flex justify-content-center">
      <div class="text-center">
        <div class="spinner-border spinner-lg text-secondary mb-4" role="status">
          <span class="sr-only">Loading graph...</span>
        </div>
        <div class="font-w600">Loading graph...</div>
      </div>
    </div>
    <Chart v-else :key="options.series.length" :options="mergedOptions" />
  </div>
</template>
<script>
import { Chart } from 'highcharts-vue';

export default {
  name: 'Graph',
  components: {
    Chart
  },
  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({
        series: []
      })
    },
    loading: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      defaultChartOptions: {
        chart: {
          type: 'bar',
          height: '540rem'
        },
        title: {
          text: ''
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            month: '%b',
            year: '%b'
          },
          title: {
            text: 'Date'
          },
          labels: {
            padding: 20,
            style: {
              fontSize: '28px'
            }
          },
          lineWidth: 3,
          lineColor: '#fff',
          gridLineColor: 'transparent'
        },
        yAxis: {
          title: {
            text: 'Unit',
            margin: 10
          },
          labels: {
            padding: 20,
            rotation: -45,
            style: {
              fontSize: '28px'
            }
          },
          min: 0,
          lineWidth: 3,
          lineColor: '#fff',
          gridLineColor: 'transparent'
        },
        tooltip: {
          headerFormat: '<b>{series.name}</b><br>',
          pointFormat: '{point.name}: {point.y:,.0f}'
        },
        plotOptions: {
          series: {
            marker: {
              enabled: true
            }
          },
          line: {
            marker: {
              symbol: 'circle'
            }
          },
          bar: {
            pointWidth: 15,
            borderWidth: 0,
            groupPadding: 0.24
          }
        },
        colors: ['#4d9575', '#dddddd', '#ffd15e'],
        series: [],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                plotOptions: {
                  series: {
                    marker: {
                      radius: 2.5
                    }
                  }
                }
              }
            }
          ]
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        }
      }
    };
  },
  computed: {
    mergedOptions() {
      return {
        ...this.defaultChartOptions,
        ...this.options,
        chart: { ...this.defaultChartOptions.chart, ...(this.options.chart || {}) },
        xAxis: { ...this.defaultChartOptions.xAxis, ...(this.options.xAxis || {}) },
        yAxis: { ...this.defaultChartOptions.yAxis, ...(this.options.yAxis || {}) },
        plotOptions: { ...this.defaultChartOptions.plotOptions, ...(this.options.plotOptions || {}) }
      };
    }
  }
};
</script>
